<template>
  <div :style="{ height: ($store.state.app.client.height + 41) + 'px' }">
    <div class="content">
    </div>
  </div>
</template>

<script type="text/javascript">
import {setRouters,setBtnList} from '@/permission'
export default {
  name: "home",
  inject:['reload'],
  data() {
    return {
      form: null,
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      showlgonly: false,
      hiddenlgonly: true

    }
  },
  components: {},
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  box-sizing: border-box;
  border: 1px solid #EBEEF5;
  //background: #fff;
  background-image: url("../../assets/img/bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
